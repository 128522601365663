<template lang="">
    <div :class="`${$props.class} bg-white  rounded-lg shadow-sm sm:rounded-lg z-25`">
        <div :class="`${$props.innerClass || 'p-6'} overflow-y-visible bg-white border-b border-gray-200 rounded-lg`">
            <slot/>
        </div>
    </div>
</template>
<script>
export default {
    props: ['class', 'innerClass']
}
</script>
<style lang="">
    
</style>