<template lang="">
    <div class="w-full flex justify-between ">
        <h2 class="font-semibold text-lg text-slate-600">{{header}} <Tooltip v-if="toolTipText" :content="toolTipText" class="inline-block m-auto align-middle w-5"></Tooltip></h2>            
        <span v-if="actionText" class="font-bold cursor-pointer text-blue" @click="$emit('clicked')">{{actionText}}</span>
        <slot></slot>
    </div>
</template>
<script>
import Tooltip from '@/Components/Tooltip.vue'
export default {
    props: ['header', 'actionText', 'toolTipText'], 
    components: {Tooltip}
}
</script>
<style lang="">
    
</style>