<template lang="">
    <div>
        <div v-if="$page.props.auth?.customer?.account_status == 'onboarding'" class=" w-full z-[100] absolute  h-6 bg-yellow-500 text-darkblue text-sm font-bold flex justify-center">
            <span class="m-auto">Account is in onboarding mode. <span class="hover:underline cursor-pointer" @click="$inertia.visit(route('app.onboarding.index'))" v-if="can('app.onboarding.index')">Click here to complete onboarding.</span></span>
        </div>
    </div>
</template>
<script>
export default {
    
}
</script>
<style lang="">
    
</style>