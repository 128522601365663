<script setup>
import { computed } from 'vue';
import { Link } from '@inertiajs/inertia-vue3';

const props = defineProps(['href', 'active']);

const classes = computed(() => props.active
    ? 'md:flex   md:items-center font-bold text-blue focus:outline-none focus:border-indigo-700 transition  duration-150 ease-in-out'
    : 'md:flex   md:items-center font-bold text-gray-400 hover:text-gray-700 hover:border-turquoise focus:outline-none focus:text-gray-700 focus:border-gray-300 transition duration-150 ease-in-out'
);
</script>

<template>
    <Link :href="href" :class="classes">
        <slot />
    </Link>
</template>
