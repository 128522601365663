<script setup>
    import NavLink from '@/Components/App/Navigation/NavLink.vue';
    import OrgSwitcher from './OrgSwitcher.vue';
    import NavLinkCollapse from '@/Components/App/Navigation/NavLinkCollapse.vue';

    import { ViewGridIcon, ViewListIcon, CurrencyDollarIcon, SwitchHorizontalIcon,
    PresentationChartLineIcon, UserGroupIcon, CogIcon, QuestionMarkCircleIcon, LogoutIcon, DocumentTextIcon, 
    DatabaseIcon, UserIcon, ShieldExclamationIcon ,CashIcon, ScaleIcon, 
    ClipboardListIcon, DocumentIcon, ExclamationCircleIcon, UserRemoveIcon} from '@heroicons/vue/outline'

    import {DuplicateIcon, OfficeBuildingIcon} from '@heroicons/vue/outline'
    import { usePage } from '@inertiajs/inertia-vue3';

    import Dropdown from '@/Components/App/Forms/Dropdown.vue'
    import DropdownLink from '@/Components/DropdownLink.vue'
    import {ref, defineProps, } from 'vue';

    import { ChevronDownIcon} from '@heroicons/vue/solid'

    const url = window.location.pathname; 

    const { props } = usePage();

    const customerOptions = props.value.auth.customers.map(c => ({label: c.name, key: c.id}) )

    const handleChangeOrg = (id) =>{
        alert('change' +id)
    }
</script>

<style type="css">
    .n-button, .n-button__border{
        border: 0 !important; 
    }
</style>

<template>
        <div class="z-5 ">

            <div class="w-full flex justify-center">
                <nav :class="`pt-1 w-40   mt-12`">

                    <ul class="text-sm flex flex-col w-full p-0">

                        <li class="block align-middle" v-if="can('app.dashboard')">
                            <NavLink class="block" :href="route('app.dashboard')" :active="route().current('app.dashboard')">
                                <ViewGridIcon class="w-7" />
                                <span class="invisible md:visible md:ml-3 inline-block align-middle"  >Dashboard</span>
                            </NavLink>
                        </li>

                        <li v-if="can('app.commissions.index')"> 
                            <NavLink class="mt-7 block" :href="route('app.commissions.index')" :active="route().current('app.commissions.*')">
                                <CurrencyDollarIcon class="w-7" />
                                <span class="invisible md:visible md:ml-3 inline-block align-middle"  >Statements</span>
                            </NavLink>
                        </li>
                        
                        <li  v-if="can('app.balance_accounts.index')">     
                            <NavLink class="mt-7 block" :href="route('app.balance_accounts.index')" :active="route().current('app.balance_accounts.index')">
                                <ClipboardListIcon class="w-7" />
                                <span class="invisible md:visible md:ml-3 inline-block align-middle" >Payouts</span>
                            </NavLink>
                        </li>
                        
                        <li  v-if="can('app.customer_users.show') && $page.props.auth.customer_user?.id">     
                            <NavLink class="mt-7 block" :href="route('app.customer_users.show', {customer_user: $page.props.auth.customer_user.id, tab: 'account_balance'})" :active="route().current('app.customer_users.show', {tab: 'account_balance', customer_user: $page.props.auth.customer_user.id})">
                                <ScaleIcon class="w-7" />
                                <span class="invisible md:visible md:ml-3 inline-block align-middle">Account</span>
                            </NavLink>
                        </li>
                        
                        <li  v-if="can('app.people')">     
                            <NavLink class="mt-7 block" :href="route('app.people')" :active="route().current('app.people')">
                                <UserGroupIcon class="w-7" />
                                <span class="invisible md:visible md:ml-3 inline-block align-middle" >Users</span>
                            </NavLink>
                        </li>
                        
                        <li  v-if="can('app.bulk_absences.index')">     
                            <NavLink class="mt-7 block" :href="route('app.bulk_absences.index')" :active="route().current('app.bulk_absences.index')">
                                <ExclamationCircleIcon class="w-7" />
                                <span class="invisible md:visible md:ml-3 inline-block align-middle" >Absence</span>
                            </NavLink>
                        </li>

                        <li  class="invisible md:visible"> 
                            
                            <NavLinkCollapse class="mt-7 block" :active="route().current('app.custom_objects.*')">
                                
                                <template v-slot:main>
                                    <DatabaseIcon class="w-7" />
                                    <span class="ml-3 invisible md:visible inline-block align-middle select-none">Data</span>
                                </template>
                                

                                <NavLink class="mt-5 block" :href="route('app.custom_objects.index', obj.id)" :active="route().current('app.custom_objects.index', obj.id)" v-for="obj in $page.props.object_definitions" :key="obj.id">
                                    <DocumentIcon class="w-5 ml-6"></DocumentIcon>
                                    <span class="ml-2 inline-block align-middle select-none">{{obj.name}}</span>
                                </NavLink>

                            </NavLinkCollapse>

                        </li>
                        
                        <li>
                            <NavLink v-if="$page.props.auth.user.user_type == 'staff'" class="align-middle border-left-2 mt-7 border-gray-500 cursor-pointer text-red-500" :active="false" :href="route('adm.customers.index')" as="button" >
                                <ShieldExclamationIcon class="w-7"></ShieldExclamationIcon>
                                <span class="invisible md:visible md:ml-3 inline-block align-middle" >Backend</span>
                            </NavLink>   
                        </li>

                    </ul>


                    <div class="sm:px-5 2xl:pl-10 xl:pr-5 mt-14">
                    <!-- <hr class="border-t-2 border-b-1"/>-->


                    </div>
                    
                
                </nav>
            </div>
            <div class=" absolute flex flex-col justify-center  md:px-3 h-40 md:h-6 bottom-10  md:w-64 md:min-w-64 w-14">

                <div class="w-full mb-3 flex justify-center invisible md:visible" v-if="$page.props.auth.customers.length > 1">
                    <org-switcher></org-switcher>
                </div>

                <div class="flex justify-center w-full">
                    <NavLink class="" :active="route().current('logout')" :href="route('logout')" method="post" as="button">
                        <LogoutIcon class="ml-14 md:ml-0 w-8 md:w-4  rotate-180	" />
                        <span class="ml-1 invisible md:visible">Sign out</span>
                    </NavLink>
                    <NavLink class="invisible md:visible" v-if="can('app.settings.index')"  :active="url.startsWith('/app/settings')" :href="route('app.settings.index')" as="button">
                        <CogIcon class="ml-3 w-4"></CogIcon>
                        <span class="ml-1 invisible md:visible">Settings</span>
                    </NavLink>
                </div>
            </div>
        </div>
    
</template>
