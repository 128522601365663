<template>
    <div ref="menu" class="relative h-10 flex justify-between cursor-pointer p-2 border-b border-slate-100 text-slate-400 hover:text-darkblue transition-all" @click="open = !open">
        
        <div>
            <OfficeBuildingIcon class="inline w-5 h-5 " />
            <span class="ml-2 text-sm font-medium  truncate font-semibold">{{ customer?.name }}</span>
        </div>

        <ChevronUpIcon :class="`w-4  ml-2 transition-all ${open && 'rotate-180'}`"></ChevronUpIcon>
        <ul 
            class="absolute block w-44 rounded-md bg-white shadow-sm" 
            :class="{  'hidden': !open, 'bottom-full': open }"
            v-for="customer in customers" 
            :key="customer.id"
        >
            <li @click="$inertia.visit(route('app.account.change', customer.id))" class="select-none text-sm px-4 py-2 cursor-pointer text-md text-slate-400 hover:text-darkblue hover:bg-gray-50 truncate" v-for="customer in customers" :key="customer.id">{{ customer.name }}</li>
        </ul>
    </div>
</template>

<script>
import { OfficeBuildingIcon } from '@heroicons/vue/outline';
import { ChevronUpIcon } from'@heroicons/vue/outline';
export default {
    components: {
        OfficeBuildingIcon, ChevronUpIcon
    },
    data() {
        return {
            customers: this.$page.props.auth.customers,
            customer: this.$page.props.auth.customer,
            open: false
        }
    },
    methods: {
        onClickOutside(event) {
            if (!this.$refs.menu.contains(event.target)) {
                this.open = false;
            }
        }
    },
    mounted() {
        document.addEventListener('click', this.onClickOutside);
    },
    beforeUnmount() {
        document.removeEventListener('click', this.onClickOutside);
    },
}
</script>

<style scoped>
/* Du kan stadig have brug for lidt manuel justering afhængigt af dit design */
.bottom-full {
    transform: translateY(-2%);
}
</style>
