<script setup>
import { computed, ref } from 'vue';


const props = defineProps(['href', 'active']);

const collapsed = ref(!props.active);

import { ChevronDownIcon, ChevronUpIcon} from '@heroicons/vue/solid'

const classes = computed(() => props.active
    ? 'flex  items-center font-bold text-gray-600 focus:outline-none focus:border-indigo-700 transition  duration-150 ease-in-out'
    : 'flex  items-center font-bold hover:border-turquoise focus:outline-none focus:text-gray-700 focus:border-gray-300 transition duration-150 ease-in-out'
);

</script>

<template>
    <div>
        <div class="flex justify-between w-full cursor-pointer items-center text-gray-400 hover:text-gray-700 hover:border-turquoise " @click="collapsed = !collapsed">
            <div :class="classes">
                <slot name="main" :class="classes"></slot>
            </div>
            <ChevronDownIcon class="h-5 w-5 mr-10" v-if="collapsed" />
            <ChevronUpIcon class="h-5 w-5 mr-10 text-gray-600" v-else />
        </div>
        <div v-show="!collapsed">
            <slot></slot>
        </div>
    </div>
</template>
