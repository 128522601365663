<template>
    <div  class="h-screen overflow-none m-0">
      <Head>
        <title>Prowi</title>
      </Head>
  
      <conditional-top-streamer></conditional-top-streamer>
  
      <div class="mx-auto h-full bg-white bg-black">

        <div class="flex h-full">
          <div class="md:w-[255px] md:min-w-[255px] w-14 min-w-14 transition-all flex flex-col h-full">
            <div class="w-full bg-white h-full" id="menu">
              <div class="md:w-40 h-16 mx-auto mt-5 md:mt-12 w-20">
                <div class="flex justify-center">
                  <application-logo />
                </div>
                <div v-if="customerLogoSrc"  class="md:w-36 w-16 flex md:justify-start justify-start">
                  <img :src="customerLogoSrc" class="object-scale-down w-16 max-h-6 md:w-20" />
                </div>
              </div>
              <nav-menu />
            </div>
          </div>
  
          <div class="h-full w-full left-0 right-0 max-h-screen flex flex-col ">
            <div :class="`w-full h-28 min-h-28 block bg-white overflow-hidden relative  z-50 ${$page.props.auth?.customer?.account_status == 'onboarding' && 'mt-5'}`">
              <div class="h-full container relative">
                <div class="h-full px-12 flex w-full justify-between">
                  
                  <div class="h-full flex flex-col justify-between">
                    <div class="my-auto">
                      <h1 class="text-2xl font-bold my-auto text-darkblue">{{title}}</h1>
                    </div>
                    <div class="absolute bottom-0 left-12 font-semibold text-sm container flex text-slate-400">
                      <div class="flex my-auto">
                        <div class="text-sm breadcrumbs my-auto flex mt-1">
                          <ul v-if="breadCrumbs" class="my-auto">
                            <li v-for="crumb, idx in breadCrumbs" :key="idx" class="text-slate-400">
                              <span v-if="crumb?.link" class="hover:underline cursor-pointer transition-all" @click="$inertia.visit(crumb.link)">{{crumb.name}}</span>
                              <span v-else>{{crumb.name}}</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
  
                  <div class="flex my-auto h-full flex-row">
                    <div class="flex-grow flex flex-col text-right">
                      <div class="my-auto flex flex-col text-right">
                        <span class="font-bold text-slate-500 mr-3 hover:text-darkblue transition-all cursor-pointer" @click="$inertia.visit(route('app.profile.edit'))">{{ $page.props.auth.user.name }}</span>
                        <span class="font-semibold text-sm text-slate-300 mr-3">{{ $page.props.auth.customer_user?.user_group?.name || 'No user group' }}</span>
                      </div>
                    </div>
                    <div class="my-auto flex">
                      <user-image class="w-11 h-11 my-auto" :user="$page.props.auth.user"></user-image>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <main id="content" class="w-full h-full bg-[#F9FAFB] rounded-t-3xl container overflow-y-scroll overflow-x-hidden pt-6 px-4 md:pt-12 md:px-12 relative left-0 right-0 bottom-0">
              <slot />
            </main>
          </div>
        </div>
  
        <FlashMessage :title="$page.props.flash.success" v-if="$page.props.flash.success" type="success" />
        <FlashMessage :title="$page.props.flash.info" v-if="$page.props.flash.info" type="info" />
        <FlashMessage :title="$page.props.flash.error" v-if="$page.props.flash.error" type="error" />
      </div>
    </div>
  </template>
  
  <script>
  import ApplicationLogo from '@/Components/ApplicationLogo.vue';
  import FlashMessage from '@/Components/FlashMessage.vue';
  import NavMenu from '@/Components/App/Navigation/NavMenu.vue';
  import { RefreshIcon } from '@heroicons/vue/solid';
  import { Head } from '@inertiajs/inertia-vue3';
  import ConditionalTopStreamer from '@/Components/App/ConditionalTopStreamer.vue';
  import UserImage from '@/Components/App/UserImage.vue';
  
  export default {
    data() {
      return {
        showingNavigationDropdown: false,
        loading: true,
      };
    },
    props: ['title', 'breadCrumbs', 'showMenu'],
    components: { NavMenu, FlashMessage, ApplicationLogo, RefreshIcon, Head, ConditionalTopStreamer, UserImage },
    computed: {
      customerLogoSrc() {
        const logoUrl = this.$page.props.auth.customer_logo_url;

        return logoUrl;
      },
    },
    mounted() {
      this.$inertia.on('start', (event) => {
        this.loading = true;
      });
  
      this.$inertia.on('success', (event) => {
        this.loading = true;
      });
    },
  };
  </script>
  
  <style type="css">

  </style>
  