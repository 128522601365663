<template lang="">
    <div @mouseover="hovered = true" @mouseleave="hovered = false" :class="class">
        <InformationCircleIcon data-tooltip-target="tooltip-default" class="h-5 ml-2 cursor-help text-blue hover:text-blue "> 
        </InformationCircleIcon>
        <div v-show="hovered"  class="inline-block absolute  z-10 py-2 px-3 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm transition-opacity duration-300 tooltip ">
            {{content}}
            <div class="tooltip-arrow" data-popper-arrow></div>
        </div> 
    </div>
</template>
<script>
import {InformationCircleIcon} from '@heroicons/vue/solid'
export default {
    props: ['content', 'class'],
    components: {InformationCircleIcon},
    data(){return({hovered: false})}
}   
</script>