<template lang="">
    <div class="align-middle inline my-auto relative">
        <img :class="`my-auto rounded-full shadow object-cover brder border-slate-50 inline-block w-full h-full`" @load="loading=false" :src="user.userAvatarUrl"/>
        <Loader v-if="loading" class="absolute m-auto w-1/2 h-1/2 left-0 right-0 top-0 bottom-0" width="1/2" height="1/2" ></Loader>
    </div>
</template>
<script>

import MD5 from "crypto-js/md5";
import Loader from '@/Components/Loader.vue'
export default {
    components: {Loader},
    props: ['user', 'className'],
    data(){
        return(
            {loading: true}
        )
    },
    methods: {
        userImage(email){
            const mail = email.toLowerCase().trim(); 
            return MD5(mail).toString();
        }
    }
}
</script>
<style lang="">
    
</style>