<template>
  <div class="w-full">
    <label v-if="label" class="select-none ml-0.5 text-sm font-semibold text-slate-500">{{ label }}</label>
    <n-select
      v-model:value="model"
      v-on:search="search"
      @onClear="onClear"
      @onBlur="onBlur"
      :placeholder="placeholder"
      :filterable="filterable"
      :multiple="multiple"
      :options="options"
    >
      <template #prefix>
        <n-icon :component="FlashOutline" />
      </template>
    </n-select>
  </div>
</template>

<script setup>
import { watch } from 'vue';
import { FlashOutline } from '@vicons/ionicons5';

const props = defineProps({
  options: {
    type: Array,
    required: true,
    validator: (value) => {
      return value.every((option) => {
        return option.hasOwnProperty('label') && option.hasOwnProperty('value');
      });
    },
  },
  multiple: {
    type: Boolean,
    default: false,
  },
  filterable: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: 'Please select',
  },
  label: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['onSearch', 'onClear', 'onBlur']);

const model = defineModel();

watch(model, (newValue) => {
  model.value = newValue;
});

const search = (text) => {
  emit('onSearch', text);
};

const onClear = () => {
  emit('onClear');
};

const onBlur = () => {
  emit('onBlur');
};
</script>

<style>
.n-base-selection {
  --n-border-radius: 10px !important;
}
</style>